<script lang="ts" setup>
import { SBadge } from '@components/badge';
import { VIcon } from '@components/v-icon';

import type { ReferralProgramStatusProps } from './types';
import { useReferralProgramStatus } from './composables';

const props = defineProps<ReferralProgramStatusProps>();

const { iconProperty, statusLabel, badgeKind } = useReferralProgramStatus(props);
</script>

<template>
  <div v-auto-id="'ReferralProgramStatus'">
    <div v-if="null && isBadgeStatus">
      <SBadge
        :label="statusLabel"
        :icon-name="iconProperty.name"
        :icon-size="iconProperty.size"
        :type="badgeKind"
        size="large"
      />
    </div>
    <div
      v-else
      :class="{
        [$style['referral-program-status']]: true,
        [$style['referral-program-status--has-label']]: isLabel,
      }"
    >
      <div
        :class="{
          [$style['referral-program-status__content']]: true,
          [$style[`referral-program-status__content--${status.toLowerCase()}`]]: true,
        }"
      >
        <div :class="$style['referral-program-status__round-container']">
          <VIcon
            :class="$style['referral-program-status__icon']"
            v-bind="iconProperty"
          />
        </div>
        <span
          v-if="isLabel"
          :class="$style['referral-program-status__label']"
        >{{ statusLabel }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.referral-program-status {
  $self: &;

  display: flex;

  &--has-label {
    @include statusWithLabel;
  }

  &__content {
    display: inline-flex;
    gap: 8px;
    align-items: center;

    &--pending {
      @include availablePending;
    }

    &--available {
      @include availableStatus;
    }

    &--completed {
      @include completedStatus;
    }
  }

  &__round-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $referralProgramStatusRoundContainerWidth;
    height: $referralProgramStatusRoundContainerHeight;
    background-color: var(--BrandDefault);
    border: 1px solid var(--Layer1);
    border-radius: 50%;
  }

  &__label {
    @include regular\12\12;
  }
}
</style>
