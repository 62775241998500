import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  onMounted,
  reactive,
  ref,
} from 'vue';

import { useI18n } from '@core/i18n';

import type {
  MemberList,
  ReferralProgramMemberListProps,
} from 'web/src/modules/referral-program/components/ReferralProgramMemberList/types';
import { MemberListEnum } from 'web/src/modules/referral-program/components/ReferralProgramMemberList/enums';

export interface ReferralProgramMemberListComposable {
  isAnimated: Ref<boolean>;
  isOpen: Record<string, boolean>;
  blockRef: Record<string, Optional<HTMLDivElement>>;
  blockStyle: ComputedRef<Record<string, string | number>>;
  memberList: ComputedRef<MemberList[]>;
  toggleOpen(name: string): void;
  setBlockRef(member: keyof typeof MemberListEnum): (el: HTMLDivElement) => void;
}

export default function useReferralProgramMemberList(
  props: ReferralProgramMemberListProps,
): ReferralProgramMemberListComposable {
  const { $translate } = useI18n();

  const isAnimated = ref<boolean>(false);

  const isOpen = reactive<Record<string, boolean>>({
    [MemberListEnum.AVAILABLE]: true,
    [MemberListEnum.WAITING]: !props.showBonusesList,
    [MemberListEnum.RECEIVED]: !props.showBonusesList,
  });

  const blockRef = reactive<Record<string, Optional<HTMLDivElement>>>({
    [MemberListEnum.AVAILABLE]: undefined,
    [MemberListEnum.WAITING]: undefined,
    [MemberListEnum.RECEIVED]: undefined,
  });

  const blockHeight = reactive<Record<string, number>>({
    [MemberListEnum.AVAILABLE]: 0,
    [MemberListEnum.WAITING]: 0,
    [MemberListEnum.RECEIVED]: 0,
  });

  const blockStyle = computed<Record<string, string | number>>(() => ({
    [MemberListEnum.AVAILABLE]: isOpen[MemberListEnum.AVAILABLE] ? `${blockHeight[MemberListEnum.AVAILABLE]}px` : 0,
    [MemberListEnum.WAITING]: isOpen[MemberListEnum.WAITING] ? `${blockHeight[MemberListEnum.WAITING]}px` : 0,
    [MemberListEnum.RECEIVED]: isOpen[MemberListEnum.RECEIVED] ? `${blockHeight[MemberListEnum.RECEIVED]}px` : 0,
  }));

  const memberList = computed<MemberList[]>(() => ([
    {
      name: MemberListEnum.AVAILABLE,
      title: $translate('WEB2_REFERRAL_PROGRAM_AVAILABLE_BONUS').value,
      list: props.availableReferrals,
    },
    {
      name: MemberListEnum.WAITING,
      title: $translate('WEB2_REFERRAL_PROGRAM_WAITING').value,
      list: props.pendingReferrals,
    },
    {
      name: MemberListEnum.RECEIVED,
      title: $translate('WEB2_REFERRAL_PROGRAM_RECIVED').value,
      list: props.completedReferrals,
    },
  ]));

  function calculateBlockHeight() {
    for (const name of Object.values(MemberListEnum)) {
      const element = blockRef[name];
      if (element) {
        blockHeight[name] = element.clientHeight || 0;
      }
    }
  }

  function toggleOpen(name: MemberListEnum) {
    if (!isAnimated.value) {
      isAnimated.value = true;
    }

    const element = blockRef[name];
    if (element) {
      blockHeight[name] = element.clientHeight || 0;
    }

    isOpen[name] = !isOpen[name];
  }

  function setBlockRef(member: keyof typeof MemberListEnum): (el: HTMLDivElement) => void {
    return function setRef(el: HTMLDivElement) {
      blockRef[member] = el;
    };
  }

  onMounted(calculateBlockHeight);

  return {
    isAnimated,
    isOpen,
    blockRef,
    blockStyle,
    memberList,
    toggleOpen,
    setBlockRef,
  };
}
