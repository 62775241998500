import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { ProgramParticipationStatus } from '@leon-hub/api-sdk';

import { useI18n, useI18nStore } from '@core/i18n';

import { getRewardTitle } from 'web/src/modules/referral-program/utils/referralProgramPopup';

import type { ReferralProgramMemberItemProps } from '../types';

export interface ReferralProgramMemberItemComposable {
  classObject: ComputedRef<Record<string, boolean | undefined>>;
  label: ComputedRef<string>;
}

export default function useReferralProgramMemberItem(props: ReferralProgramMemberItemProps):
ReferralProgramMemberItemComposable {
  const { $translate } = useI18n();
  const { locale } = useI18nStore();

  const label = computed<string>(() => {
    if (props.status === ProgramParticipationStatus.AVAILABLE) {
      return $translate('WEB2_REFERRAL_PROGRAM_CHOOSE_BONUS').value;
    }
    if (props.status === ProgramParticipationStatus.PENDING) {
      return `${$translate('WEB2_REFERRAL_PROGRAM_CONDITIONS').value} ${props.currentStep}/${props.totalSteps}`;
    }

    if (props.playerReward) {
      return getRewardTitle(props.playerReward, $translate, locale);
    }

    return '';
  });

  const classObject = computed<Record<string, boolean | undefined>>(() => ({
    'referral-program-member-item': true,
    [`referral-program-member-item--${props.status}`]: props.status !== ProgramParticipationStatus.PENDING,
  }));
  return {
    label,
    classObject,
  };
}
