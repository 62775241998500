import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type { VButtonProps } from '@components/buttons';
import type { VIconProps } from '@components/v-icon';
import { ButtonHeight, ButtonKind } from '@components/buttons';

import type { ReferralProgramHeaderEmits, ReferralProgramHeaderProps } from '../types';

export interface ReferralProgramPopupHeaderComposable {
  iconProps: ComputedRef<VIconProps>;
  buttonProps: ComputedRef<VButtonProps>;
  handleClickOnContainer(): void;
  handleRedirectToBonuses(): void;
  handleClose(): void;
}

export default function useReferralProgramPopupHeader(
  props: ReferralProgramHeaderProps,
  emits: ReferralProgramHeaderEmits,
): ReferralProgramPopupHeaderComposable {
  const { $translate } = useI18n();

  const iconProps = computed<VIconProps>(() => {
    let params: VIconProps = {
      name: IconName.EXPAND_DOWN,
    };

    if (props.isOpen) {
      params.name = IconName.EXPAND_UP;
    }

    if (props.successMessage) {
      params = {
        name: IconName.CROSS,
        size: IconSize.SIZE_16,
      };
    }

    return params;
  });

  const buttonProps = computed<VButtonProps>(() => ({
    kind: ButtonKind.MONOCHROME,
    label: $translate('WEB2_GRAB').value,
    height: ButtonHeight.TINY,
  }));

  const handleClickOnContainer = (): void => {
    if (!props.successMessage) {
      emits('click');
    }
  };

  const handleClose = (): void => {
    if (props.successMessage) {
      emits('close');
    }
  };

  const handleRedirectToBonuses = (): void => {
    if (props.successMessage) {
      emits('redirect');
    }
  };

  return {
    iconProps,
    buttonProps,
    handleClickOnContainer,
    handleRedirectToBonuses,
    handleClose,
  };
}
