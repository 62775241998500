<script setup lang="ts">
import { VButton } from '@components/buttons';
import { VIcon } from '@components/v-icon';

import type { ReferralProgramHeaderEmits, ReferralProgramHeaderProps } from 'web/src/modules/referral-program/components/ReferralProgramPopupHeader/types';

import { useReferralProgramPopupHeader } from './composables';

const props = withDefaults(defineProps<ReferralProgramHeaderProps>(), {});

const emits = defineEmits<ReferralProgramHeaderEmits>();

const {
  iconProps,
  buttonProps,
  handleClickOnContainer,
  handleRedirectToBonuses,
  handleClose,
} = useReferralProgramPopupHeader(props, emits);
</script>

<template>
  <div v-auto-id="'ReferralProgramPopupHeader'"
    :class="{
      [$style['referral-program-popup-header']]: true,
      [$style['referral-program-popup-header_pointer']]: !successMessage,
    }"
    @click="handleClickOnContainer"
  >
    <div :class="$style['referral-program-popup-header__left']">
      <div :class="$style['referral-program-popup-header__left-content']">
        <p :class="$style['referral-program-popup-header__title']">
          {{ successMessage ? $t('WEB2_REFFERAL_PROGRAM_POPUP_BONUS_TITLE') : $t('WEB2_REFFERAL_PROGRAM_POPUP_TITLE') }}
        </p>
        <p :class="$style['referral-program-popup-header__steps']">
          <span v-if="successMessage">{{ successMessage }}</span>
          <template v-else>
            {{ $t('WEB2_REFFERAL_PROGRAM_POPUP_STEPS') }} <span>{{ activeStep }}</span>/{{ stepCount }}
          </template>
        </p>
      </div>

      <VButton
        v-if="successMessage"
        v-bind="buttonProps"
        @click="handleRedirectToBonuses"
      />
    </div>
    <div :class="$style['referral-program-popup-header__right']">
      <div :class="$style['referral-program-popup-header__banner']">
        <img
          :src="require(`web/src/modules/referral-program/image/banner.png`)"
        >
      </div>
      <div
        class="referral-program-popup-header__arrow"
        :class="{
          [$style['referral-program-popup-header__arrow']]: true,
          [$style['referral-program-popup-header__arrow-opened']]: !successMessage && isOpen,
        }"
        @click="handleClose"
      >
        <VIcon v-bind="iconProps" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.referral-program-popup-header {
  display: flex;
  justify-content: space-between;
  height: 48px;

  &_pointer {
    cursor: pointer;
  }

  &__left {
    display: inline-flex;
    column-gap: 16px;
    align-items: center;
    padding: 8px 0 8px 16px;

    &-content {
      display: inline-flex;
      flex-direction: column;
    }
  }

  &__right {
    display: flex;
    overflow: hidden;
  }

  &__title {
    @include medium\12\16;

    margin: 0;
    color: var(--DAlertButtonText);
  }

  &__arrow {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 48px;
    color: var(--DAlertButtonText);
    cursor: pointer;
    transform: rotate(0deg);
    transition: 0.4s ease-out;
  }

  &__steps {
    @include medium\14\16\025;

    margin: 0;
    color: var(--DAlertButtonText);
  }

  &__banner {
    height: 48px;

    img {
      height: 100%;
    }
  }
}
</style>
