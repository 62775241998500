import type { Ref } from 'vue';
import { computed } from 'vue';

import type { IconNameType } from '@leon-hub/icons';
import { ProgramParticipationStatus } from '@leon-hub/api-sdk';
import { IconName, IconSize } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type { SBadgeType } from '@components/badge';
import type { VIconProps } from '@components/v-icon';

import type { ReferralProgramStatusProps } from '../types';

export interface ReferralProgramStatusComposable {
  iconProperty: Ref<VIconProps>;
  statusLabel: Ref<string>;
  statusIcon: Ref<IconNameType>;
  badgeKind: Ref<SBadgeType>;
}

export default function useReferralProgramStatus(props: ReferralProgramStatusProps): ReferralProgramStatusComposable {
  const { $translate } = useI18n();

  const statusIcon = computed(() => {
    if (props.status === ProgramParticipationStatus.PENDING) {
      return process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_CLOCK : IconName.CLOCK;
    }
    if (props.status === ProgramParticipationStatus.AVAILABLE) {
      return process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_GIFT2 : IconName.PROMOS;
    }
    return IconName.CHECK;
  });

  const iconProperty = computed<VIconProps>(() => ({
    size: props.isBadgeStatus && !process.env.VUE_APP_SKIN_TWIN ? IconSize.SIZE_20 : IconSize.SIZE_12,
    name: statusIcon.value,
  }));

  const statusLabel = computed(() => {
    if (props.status === ProgramParticipationStatus.PENDING) {
      return $translate('WEB2_REFFERAL_PROGRAM_WAITING').value;
    }
    if (props.status === ProgramParticipationStatus.AVAILABLE) {
      return $translate('WEB2_REFFERAL_PROGRAM_BONUS').value;
    }
    return $translate('WEB2_REFFERAL_PROGRAM_READY').value;
  });

  const badgeKind = computed(() => {
    if (props.status === ProgramParticipationStatus.PENDING) {
      return 'additional-3';
    }
    if (props.status === ProgramParticipationStatus.AVAILABLE) {
      return 'additional-2';
    }
    return 'brand';
  });

  return {
    iconProperty,
    statusLabel,
    statusIcon,
    badgeKind,
  };
}
