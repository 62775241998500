<script lang="ts" setup>
import { onMounted } from 'vue';

import { IconName } from '@leon-hub/icons';

import { ButtonKind, VButton } from '@components/buttons';
import { VIcon } from '@components/v-icon';

import { VLoaderDelayed } from 'web/src/components/Loader';
import SNumberedList from 'web/src/components/NumberedList/SNumberedList/SNumberedList.vue';
import SNumberedListItem from 'web/src/components/NumberedListItem/SNumberedListItem/SNumberedListItem.vue';
import ReferralProgramInfoBonus
  from 'web/src/modules/referral-program/pages/ReferralProgramInfoPage/components/ReferralProgramInfoBonus/ReferralProgramInfoBonus.vue';
import ReferralProgramRewardItem
  from 'web/src/modules/referral-program/pages/ReferralProgramInfoPage/components/ReferralProgramRewardItem/ReferralProgramRewardItem.vue';
import useReferralProgramInfoPage
  from 'web/src/modules/referral-program/pages/ReferralProgramInfoPage/composables/useReferralProgramInfoPage';

const {
  itemList,
  isLoading,
  nominals,
  runOnMounted,
  rewardMatrix,
  onClickTerms,
} = useReferralProgramInfoPage();

onMounted(runOnMounted);
</script>

<template>
  <VLoaderDelayed v-if="isLoading" />
  <div
    v-else
    :class="$style['referral-program-info-page']"
  >
    <template v-if="null">
      <SNumberedList :title="$t('WEB2_REFERRAL_PROGRAM_HOW_IT_WORKS')">
        <SNumberedListItem
          v-for="(item, index) in itemList"
          :key="`item_${index}`"
          :step="index !== 3 ? String(index + 1) : undefined"
          :icon="index === 3 ? IconName.SLOTT_GIFT2 : undefined"
          :completed="index === 3"
          :progress="index !== 3"
        >
          <template #text>
            {{ item.label }}
          </template>
        </SNumberedListItem>
      </SNumberedList>
      <ReferralProgramInfoBonus
        :matrix="rewardMatrix"
        :nominals="nominals"
      />
    </template>
    <template v-else>
      <div
        v-for="(item, index) in itemList"
        :key="index"
        v-data-test="{ el: 'referral-program-info-page-item' }"
        :class="$style['referral-program-info-page__item']"
      >
        <div :class="$style['referral-program-info-page__item-content']">
          <div :class="$style['referral-program-info-page__icon']">
            <VIcon
              :class="$style['referral-program-info-page__icon-svg']"
              :name="item.icon"
            />
          </div>
          <span :class="$style['referral-program-info-page__item-label']">{{ item.label }}</span>
        </div>
        <div
          v-if="item.table"
          :class="$style['referral-program-info-page__table']"
        >
          <div
            :class="{
              [$style['referral-program-info-page__table-row']]: true,
              [$style['referral-program-info-page__table-row--header']]: true,
            }"
          >
            <span
              v-for="title in item.table.header"
              :key="title"
              :class="$style['referral-program-info-page__table-cell']"
            >{{ title }}
            </span>
          </div>
          <div :class="$style['referral-program-info-page__table-rows']">
            <div
              v-for="(cell, cellIndex) in item.table.body"
              :key="`cell_${cellIndex}`"
              :class="$style['referral-program-info-page__table-row']"
            >
              <div :class="$style['referral-program-info-page__table-cell']">
                {{ cell.col1 }}
              </div>
              <div :class="$style['referral-program-info-page__table-cell']">
                <ReferralProgramRewardItem :items="cell.col2" />
              </div>
              <div :class="$style['referral-program-info-page__table-cell']">
                <ReferralProgramRewardItem :items="cell.col3" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-data-test="{ el: 'referral-program-info-page-label' }"
        :class="$style['referral-program-info-page__label']"
      >
        {{ $t('REFERRAL_PROGRAM_INFO_LABEL') }}
        <span
          v-for="(item, index) in nominals"
          :key="index"
        >
          {{ item.title }}&nbsp;-&nbsp;{{ item.value }}<template v-if="index < nominals.length - 1">, </template>
        </span>
      </div>
      <VButton
        v-if="null"
        :class="[$style['payments-system__form__button']]"
        :label="$t('WEB2_REFERRAL_PROGRAM_INFO_BUTTON')"
        :kind="ButtonKind.SECONDARY_DARK"
        full-width
        @click="onClickTerms"
      />
    </template>
  </div>
</template>

<style lang="scss" module>
.referral-program-info-page {
  display: flex;
  flex-direction: column;
  gap: $refProgramInfoPageGap;
  padding: $refProgramInfoPagePaddingMobile;

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--OpacityLayer1);
    border-radius: var(--BorderRadius);

    &-content {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 12px;
    }

    &-label {
      @include regular\14\20\025;

      width: calc(100% - 64px);
      color: var(--TextPrimary);
    }
  }

  &__label {
    @include regular\14\24\025;

    color: var(--TextPrimary);
    text-align: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: var(--OpacityBrandDefault);
    border-radius: 50%;

    &-svg {
      fill: var(--BrandDefault);
    }
  }

  &__table {
    @include regular\12\16;

    display: flex;
    flex-direction: column;
    gap: 1px;
    overflow: hidden;
    background-color: var(--Layer0);
    border-top: 1px solid var(--Layer0);
    border-radius: 0 0 var(--BorderRadius) var(--BorderRadius);

    &-rows {
      display: flex;
      flex-direction: column;
      gap: 1px;
      color: var(--TextPrimary);
    }

    &-row {
      display: flex;
      gap: 12px;
      width: 100%;
      padding: 12px;
      text-align: center;
      background-color: var(--OpacityLayer1);

      &:nth-child(even) {
        background-color: var(--OpacityLayer2);
      }

      &--header {
        color: var(--TextSecondary);
        background-color: var(--OpacityLayer2);
      }
    }

    &-cell {
      &:nth-child(1) {
        display: flex;
        align-items: center;
        width: 23%;
      }

      &:nth-child(2) {
        width: 50%;
      }

      &:nth-child(3) {
        display: flex;
        align-items: center;
        width: 27%;
      }
    }
  }

  @include is-app-layout-desktop {
    padding: $refProgramInfoPagePaddingDesktop;
  }
}
</style>
