import type { ComputedRef } from 'vue';
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import type {
  ReferralProgramRegistrationBodyProps,
} from 'web/src/modules/referral-program/components/ReferralProgramRegistrationBody/types';
import { useReferralProgramStore } from 'web/src/modules/referral-program/store';

export interface ReferralProgramRegistrationComposable {
  rewards: ComputedRef<Optional<ReferralProgramRegistrationBodyProps>>;
  handleClick(): void;
}

export default function useReferralProgramRegistration(): ReferralProgramRegistrationComposable {
  const router = useRouter();
  const referralProgramStore = useReferralProgramStore();
  const rewardsData = toRef(referralProgramStore, 'rewards');

  const rewards = computed(() => (
    rewardsData.value ?? undefined) as Optional<ReferralProgramRegistrationBodyProps>);

  const handleClick = (): void => {
    void router.push({ name: RouteName.REGISTRATION });
  };

  const initialize = async () => {
    const referralCode = router.getParam('code')!;

    try {
      await referralProgramStore.setRewards(referralCode || '');
    } catch {
      void router.push({ name: RouteName.HOME });
    }
  };

  void initialize();

  return {
    rewards,
    handleClick,
  };
}
