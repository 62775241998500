<script setup lang="ts">
import { computed } from 'vue';

import { useI18n } from '@core/i18n';

const { $translate } = useI18n();

const title = computed(() => $translate('WEB2_REFERRAL_PROGRAM_BANNER_TEXT').value);

let imgSrc: string;
if (null
  || null) {
  imgSrc = require('web/src/modules/referral-program/image/li/default/banner.png');
} else {
  imgSrc = require('web/src/modules/referral-program/image/banner.png');
}
</script>

<template>
  <div v-auto-id="'VReferralProgramBanner'" :class="$style['referral-program__wrapper']">
    <img
      :class="$style['referral-program__image']"
      :src="imgSrc"
    >
    <span :class="$style['referral-program__text']">{{ title }}</span>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .referral-program {
    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      justify-content: center;
      height: 148px;
      padding: 32px 16px;
      overflow: hidden;
      cursor: pointer;
      background-color: var(--Highlight);
      border-radius: 5px;
    }

    &__text {
      @include medium\24\28;

      z-index: 1;
      width: 160px;
      color: $textColor;
    }

    &__image {
      @include refProgramBannerImage($isDesktop);

      position: absolute;
      bottom: 0;
    }
  }
}
</style>
