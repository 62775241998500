import type { Ref } from 'vue';
import { useRouter } from 'vue-router';

import { IconName, IconSize } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import { useI18n } from '@core/i18n';

import type { VButtonProps } from '@components/buttons';
import { ButtonKind } from '@components/buttons';

import type {
  ReferralProgramRegistrationTopBarEmits,
  ReferralProgramRegistrationTopBarProps,
} from 'web/src/modules/referral-program/components/ReferralProgramRegistrationTopBar/types';

export interface ReferralProgramRegistrationTopBarComposable {
  iconProps: VButtonProps;
  registrationTitle: Ref<string>;
  brandName: Ref<string>;
  handleClick(): void;
}

export default function useReferralProgramRegistrationTopBar(
  props: ReferralProgramRegistrationTopBarProps,
  emit: ReferralProgramRegistrationTopBarEmits,
): ReferralProgramRegistrationTopBarComposable {
  const router = useRouter();
  const { $translate } = useI18n();

  const iconProps: VButtonProps = {
    iconSize: IconSize.SIZE_24,
    kind: ButtonKind.TRANSPARENT,
    iconName: IconName.CROSS,
  };

  const brandName = $translate('WEB2_BRAND_NAME_SHORT');
  const registrationTitle = $translate('WEB2_REFERRAL_REGISTRATION_TITLE');

  const handleClick = (): void => {
    if (!props.disableRedirect) {
      if (process.env.VUE_APP_LAYOUT_PHONE) {
        void router.replace({
          name: RouteName.HOME,
        });
      } else {
        void router.closeModal();
      }
    }

    emit('click');
  };

  return {
    iconProps,
    registrationTitle,
    brandName,
    handleClick,
  };
}
