<script lang="ts" setup>
import { VButton } from '@components/buttons';

import type {
  ReferralProgramRegistrationTopBarEmits,
  ReferralProgramRegistrationTopBarProps,
} from 'web/src/modules/referral-program/components/ReferralProgramRegistrationTopBar/types';
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
import {
  useReferralProgramRegistrationTopBar,
} from 'web/src/modules/referral-program/components/ReferralProgramRegistrationTopBar/composables';

const props = withDefaults(defineProps<ReferralProgramRegistrationTopBarProps>(), {
});

const emit = defineEmits<ReferralProgramRegistrationTopBarEmits>();

const {
  iconProps,
  registrationTitle,
  brandName,
  handleClick,
} = useReferralProgramRegistrationTopBar(props, emit);
</script>

<template>
  <div v-auto-id="'ReferralProgramRegistrationTopBar'" :class="$style['referral-program-top-bar']">
    <div :class="$style['referral-program-top-bar__content']">
      <div :class="$style['referral-program-top-bar__left']">
        <span :class="$style['referral-program-top-bar__title']">
          {{ registrationTitle }}
        </span>

        <VBrand
          :class="$style['referral-program-top-bar__logo']"
          :alt="brandName"
          :is-dark="null ?? null ? true : undefined"
        />

        <div
          v-if="!null || (null && null)"
          :class="$style['referral-program-top-bar__close']"
        >
          <VButton
            v-bind="iconProps"
            :class="$style['referral-program-top-bar__close-button']"
            @click="handleClick"
          />
        </div>
      </div>
      <div :class="$style['referral-program-top-bar__right']">
        <img
          v-if="null"
          :class="$style['referral-program-top-bar__image']"
          :src="require('web/src/modules/referral-program/image/li/default/banner-gift.png')"
          alt=""
        >
        <img
          v-else-if="null"
          :class="$style['referral-program-top-bar__image']"
          :src="require('web/src/modules/referral-program/image/li/default/banner.png')"
          height="120"
          alt=""
        >
        <img
          v-else-if="null"
          :class="$style['referral-program-top-bar__image']"
          :src="require('web/src/modules/referral-program/image/li/default/banner-main.png')"
          height="120"
          alt=""
        >
        <img
          v-else
          :class="$style['referral-program-top-bar__image']"
          :src="require(`web/src/modules/referral-program/image/banner-registration.png`)"
          height="120"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.referral-program-top-bar {
  @include refTopBar;

  position: relative;
  z-index: 1;
  width: 100%;

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: $referralProgramTopBarContentPaddingPhone;
    overflow: hidden;

    @include is-app-layout-desktop {
      padding: $referralProgramTopBarContentPaddingDesktop;
    }
  }

  &__left {
    z-index: 1;
    display: inline-flex;
    flex-direction: column;
  }

  &__logo {
    width: $referralProgramTopBarLogoWidthPhone;
    height: auto;
    margin-top: $referralProgramTopBarLogoMarginTopPhone;

    @include is-app-layout-desktop {
      width: $referralProgramTopBarLogoWidthDesktop;
      margin-top: $referralProgramTopBarLogoMarginTopDesktop;
    }
  }

  &__close {
    @include referralProgramTopBarCloseWrapper;

    position: absolute;

    &-button {
      color: $referralProgramTopBarCloseButtonColor;

      @include for-hover {
        &:hover:not([disabled]) {
          color: var(--TextDefault);
          background-color: transparent;
        }
      }

      &:focus-visible {
        color: var(--TextDefault);
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }

  &__right {
    padding-right: 16px;

    @include is-app-layout-desktop {
      padding-right: 0;
    }
  }

  &__image {
    display: block;
    padding-right: $referralProgramTopBarImagePaddingRight;
  }
}
</style>
