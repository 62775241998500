import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { AsyncAbstractPrefetchResult } from '@core/router';
import { useAnalytics } from '@core/analytics';
import { AbstractPrefetch } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';

import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
import { useReferralProgramStore } from 'web/src/modules/referral-program/store';

export default class ReferralProgramPrefetch extends AbstractPrefetch {
  async prefetch(): AsyncAbstractPrefetchResult {
    const siteConfigStore = useSiteConfigStore();
    const isProfileReferralProgramEnabled = toRef(() => siteConfigStore.isProfileReferralProgramEnabled);
    const analytics = useAnalytics();

    if (!isProfileReferralProgramEnabled.value) {
      return { name: RouteName.ERROR_FORBIDDEN_403 };
    }
    const referralStore = useReferralProgramStore();
    await referralStore.getAvailability();
    await referralStore.getRefDetails();

    const availability = toRef(() => referralStore.availability);
    const programDetails = toRef(() => referralStore.programDetails);

    if (!availability.value?.available) {
      return { name: RouteName.ERROR_FORBIDDEN_403 };
    }

    if (!programDetails.value?.confirmed) {
      analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
        referFriendProgram: {
          openSection: 'accept',
        },
      });
      return {
        name: RouteName.ONBOARDING,
        params: { id: OnboardingIdsEnum.REFERRAL_PROGRAM },
      };
    }
  }
}
