import type { RouteLocationNormalized } from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult, AppVueRouter } from '@core/router';
import { AbstractPrefetch } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';

export default class ReferralProgramRegistrationPrefetch extends AbstractPrefetch {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
  ): AbstractPrefetchResult {
    const isProfileReferralProgramEnabled = toRef(useSiteConfigStore(), 'isProfileReferralProgramEnabled');

    if (!isProfileReferralProgramEnabled.value || !to.params.code) {
      return {
        name: RouteName.HOME,
      };
    }
  }
}
